class UploadPitcures {
    constructor() {
        this.path = null;
    }

    public path: any;

}
export class ExpenseDetails {
    constructor() {
        this.expenseCategoryId = null;
        
        this.expenseOn = null;
        this.quantity = null;
        // this.currency = null;
        this.amount = null;
        this.spentDate = new Date();
        this.paidThrough = null;
        this.spentDetails = null;
        this.bill = new Array<UploadPitcures>();
        this.createdOn = new Date();
        this.createdBy = null;
        this.updatedOn = new Date();
        this.updatedBy = null;
        this.purchaser = null;
        this.seller = null;
        
    }
    public expenseCategoryId: any;
    public expenseOn: any;
    public quantity: any;
    // public currency: any;
    public amount: any;
    public spentDate: Date;
    public paidThrough: any;
    public spentDetails: any;
    public bill: Array<UploadPitcures>;
    public seller: any;
    public purchaser: any;
    public updatedOn: Date;
    public updatedBy: any;
    public createdOn: Date;
    public createdBy: any;

}
    

    


