

import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL, AWS_S3_BASE_URL } from "../../../../../config";
import { ExpenseDetails } from "../../../../../models/sub-domain/expenseDetails.model";
import { authHeader } from "../../../../../services/auth";
import Datepicker from "@hokify/vuejs-datepicker";
import {
  MONTH_NAMES,
  CURRENCY,
} from "../../../../../constants/sub-domain/constants";
import moment from "moment";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import DisplayFiles from "../../../../../common-components/sub-domain/views/DisplayFile.vue";
import ExpenseView from "./ExpenseView.vue";
import spreadSheet from "../../../employee/timesheet/SpreadSheet.vue";
import Multiselect from "vue-multiselect";

@Component({
  components: {
    Datepicker,
    PerfectScrollbar,
    DisplayFiles,
    ExpenseView,
    spreadSheet,
    Multiselect,
  },
})
export default class Expense extends Vue {
  public inputValues: any = new ExpenseDetails();
  public addedType: any = null;
  public category: any = {};
  public inProcessings:any=false;
  public details: any = {};
  public employeeList: any = [];
  public categoryList: any = [];
  public loading:boolean = false;
  // public organisationCurrency: any = null;
  // public currency= CURRENCY;

  public skip = 0;
  public currentLengthOnScroll:any=0;
  public totalLength :any= 0;

  public fromDate: any = null;
  public toDate: any = null;
  public expenseFilterBY: any = "All";
  public expensefilterBYArray: any = ["All","Today","Weekly","Monthly","Yearly","Custom Date Range"];
  public filterByDate: any = null;
  public filterByWeek: any = null;
  public filterByMonth = new Date().getMonth() + 1;
  public hasAccess:any = [];
  public filterByYear = new Date().getFullYear();
  public customDate: any = false;
  public monthly: any = false;
  public yearly: any = false;
  public filePath:any = {}
  public i:any = null;
  public weekly: any = false;
  public organisationStartDate: any = null;

  public base64String: any = null;
  public viewAll: any = true;

  public xlsData: any = null;
  public NewxlsData: any = [];
  public xlsArr: any = [];
  public totalAmount: any = null;
  public expenseList: any = [];
  // public categoryNameArray: any = [];
  public bill: any = [];y
  public uploadFile: any = null;
  public submitButton = "Add";
  public isDuplicate = true;
  public weekNumber:any=null;
  public deleteExpenseId = null;
  public categoryId = null;
  public inProcessing = false;
  public AWS_S3_BASE_URL = AWS_S3_BASE_URL;
  public id = null;
  public billArrayLength = 0;
  $snotify: any;
  public disabledToDates = {
    to: new Date(this.toDate),
  };
  public disabledFromDates = {
    to: new Date(this.fromDate),
  };
  public disabledOrgDate = {
    to: new Date(this.organisationStartDate),
  };
  /**
   * for disabling the dates before 'from date'
   **/
  public disableToDate() {
    this.disabledToDates = {
      to: new Date(this.fromDate),
    };
  }

  public disableOrgDate() {
    this.disabledOrgDate = {
      to: new Date(this.organisationStartDate),
    };
  }
  /**
   * reset the selected month/year/dates on change of other filter
   **/
  public async resetFilters() {
    this.filterByMonth = new Date().getMonth() + 1;
    this.filterByYear = new Date().getFullYear();
    this.fromDate = null;
    this.toDate = null;
  }
  public clearFilters() {
    this.expenseFilterBY = "All";
    this.getExpenseList(true, null, null, "");
  }

  /**
   * To get month names in dropdown
   **/
  public get monthName() {
    let presentMonthName = new Date().getMonth() + 1;
    let presentYear = new Date().getFullYear();
    let selectedYear = this.filterByDate
      ? new Date(this.filterByDate).getFullYear()
      : this.filterByYear;

    if (selectedYear < presentYear) {
      let monthName = MONTH_NAMES.filter((month) => month.monthNumber);
      return monthName;
    } else {
      let monthName = MONTH_NAMES.filter(
        (month) => month.monthNumber <= presentMonthName
      );

      return monthName;
    }
  }

  /**
   * To get years from start of the Org to current year
   */
  public get years() {
    if (this.organisationStartDate) {
      const year = new Date().getFullYear();
      this.filterByYear = year;
      let organisationStartDate = moment(this.organisationStartDate).format(
        "YYYY"
      );
      let years: any = Array.from(
        { length: year - 1970 },
        (value, index) => year - index,

      );

      return years.filter((year) => year >= organisationStartDate);
    }
  }

  /**
   * To get the Org starting year
   */
  public async getOrganisationInfo() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "employee/getOrganisationInfo",
        {
          headers: authHeader(),
        }
      );

      this.organisationStartDate = response.data.data
        ? response.data.data.startedOn
        : null;
      // this.organisationCurrency = response.data.data
      //   ? response.data.data.currency
      //   : null;
      // this.organisationCurrency = this.organisationCurrency.substring(-1,1)
    } catch (error) {
      console.log(error);
    }
  }

  /**get all the categories in select expense category
   **/
  public async fetchExpenseCategory() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/fetchExpenseCategory",
        {
          headers: authHeader(),
        }
      );
      this.categoryList = response.data;
      // for(let i=0;i<this.categoryList.length;i++){
      // this.categoryNameArray.push(this.categoryList[i].categoryName);
      // }
    } catch (error) {
      console.log(error);
    }
  }

    nameWithId(employeeId){

     return `${employeeId.name} — [${employeeId.employeeId}]`;
  }

  /**
   * to get the employee list in purchaser field
   **/
  public async getEmployeeList() {
    try {
      let response = await Axios.get(
        BASE_API_URL + "administrator/getEmployeeList",
        {
          headers: authHeader(),
        }
      );
      this.employeeList = response.data;
    } catch (error) {
      console.log(error);
    }
  }

  public toAddExpenseDetails() {
    this.inProcessings=false;
    this.viewAll = false;
    this.inputValues = new ExpenseDetails();
    this.$modal.show("detailsModel");
    this.submitButton = "Add";
    this.addedType = "Add  Expense Details";
  }

  public async closeModal() {
    this.viewAll = true;
    this.isDuplicate = true;
    this.$modal.hide("detailsModel");
    this.$modal.hide("duplicateDetails");
    this.submitButton == "Add";
    this.inProcessing = false;
    this.inputValues = new ExpenseDetails();
  }
  public async closeDuplicateModal() {
    this.$modal.hide("duplicateDetails");
    this.isDuplicate = true;
  }

  public openDuplicatePopup() {
    this.$modal.show("duplicateDetails");
    this.isDuplicate = false;
  }

  public async duplicateSave() {
    this.isDuplicate = false;
    this.saveExpenseDetails();
  }
  /**
   * to save the details written by the user
   **/
//   public async saveExpenseDetails() {
//     try {
//       this.inProcessing = true;
//       let formData = new FormData();

//       this.inputValues.bill.forEach((element: any) => {
//         formData.append("file", element);
//       });

//       if(this.inputValues.purchaser.userId){
//           this.inputValues.purchaser=this.inputValues.purchaser.userId;
//         

// }

//       // this.inputValues.purchaser=this.inputValues.purchaser.userId;
//       formData.append("data", JSON.stringify(this.inputValues));
//       formData.append("bucketName", this.$store.state.sessionObject.bucketName);
//       formData.append("type", "expense");
//       formData.append("submitType", this.submitButton);
//       formData.append("isDuplicate", String(this.isDuplicate));

//       let response = await Axios.post(
//         BASE_API_URL + "administrator/saveExpenseDetails",
//         formData,
//         {
//           headers: authHeader(),
//         }
//       );
//       if (response.data.duplicateLength >= 0) {
//         if (response.data.duplicateLength == 0) {
//           this.duplicateSave();
//         } else {
//           this.openDuplicatePopup();
//         }
//       } else if (response.data.status == 300) {
//         this.inProcessing = false;
//         this.closeModal();
//         return;
//       } else {
//         this.$snotify.remove();
//         this.$snotify.success(response.data.message);
//         this.getExpenseList(true, null, null, "");
//         this.closeModal();
//       }
//     } catch (error) {
//       console.log(error);
//     }
//     this.inProcessing = false;
//   }

 public async saveExpenseDetails() {
    try {
      this.inProcessings = true;
      let formData = new FormData();

      this.inputValues.bill.forEach((element: any) => {
        formData.append("file", element);
      });
      formData.append("data", JSON.stringify(this.inputValues));
      formData.append("bucketName", this.$store.state.sessionObject.bucketName);
      formData.append("type", "expense");
      formData.append("submitType", this.submitButton);
      formData.append("isDuplicate", String(this.isDuplicate));

      let response = await Axios.post(
        BASE_API_URL + "administrator/saveExpenseDetails",
        formData,
        {
          headers: authHeader(),
        }
      );
      if (response.data.duplicateLength >= 0) {
        if (response.data.duplicateLength == 0) {
          this.duplicateSave();
        } else {
          this.openDuplicatePopup();
        }
      } else if (response.data.status == 300) {
        this.inProcessings = false;
        this.closeModal();
        return;
      } else {
        this.$snotify.remove();
        this.$snotify.success(response.data.message);
        this.getExpenseList(true, null, null, "");
        this.closeModal();
      }
    } catch (error) {
      console.log(error);
    }
    this.inProcessing = false;
  }
  /**
   * Pagination
   **/
  public async scrollHandle(evt) {
    let x: any = document.getElementById("onScroll");

    if (
      x.scrollTop + x.clientHeight <= x.scrollHeight &&
      this.currentLengthOnScroll < this.totalLength.data.returnCategoryLength
    ) {
      await this.getExpenseList(false, null, null, "");
    }
  }

  /**
   * to display the expense list
   **/
  public async getExpenseList(mountCall, event, filterType, exportType) {
    this.$store.state.loaderTitleId = 1;
      this.$store.state.fusionLoader = true;
      this.loading = true;
    if (this.expenseFilterBY== "Custom Date Range") {
      this.filterByMonth = 0;
      this.filterByYear = 0;
      this.filterByWeek = null;
      this.fromDate;
      this.toDate;
      this.customDate = true;
      this.monthly = false;
      this.yearly = false;
      this.weekly = false;
    } else if (this.expenseFilterBY == "Monthly") {
      this.filterByMonth;
      this.filterByYear;
      this.filterByWeek = null;
      this.fromDate = null;
      this.toDate = null;
      this.customDate = false;
      this.monthly = true;
      this.yearly = false;
      this.weekly = false;
    } else if (this.expenseFilterBY == "Yearly") {
      this.filterByYear;
      this.filterByWeek = null;
      this.filterByMonth = 0;
      this.fromDate = null;
      this.toDate = null;
      this.customDate = false;
      this.monthly = false;
      this.yearly = true;
      this.weekly = false;
    } else if (this.expenseFilterBY == "Weekly") {
      if (filterType == "week" && filterType != null) {
        var week = event.target.value;
        var wkFromTo = week.split("|");
        if (wkFromTo) {
          for (let i = 0; i < wkFromTo.length; i++) {
            this.fromDate = new Date(wkFromTo[0]);
            this.toDate = new Date(wkFromTo[1]);
          }
        }
      }
      // else{
      //   this.filterByWeek = this.weeks[this.weekNumber];
      // }
      this.filterByMonth;
      this.filterByYear;
      this.customDate = false;
      this.monthly = false;
      this.weekly = true;
      this.yearly = false;
    } else if (this.expenseFilterBY == "Today") {
      this.filterByMonth = 0;
      this.filterByYear = 0;
      this.filterByWeek = null;
      this.fromDate = new Date();
      this.toDate = new Date();
      this.customDate = false;
      this.monthly = false;
      this.yearly = false;
      this.weekly = false;
    } else {
      this.filterByMonth = 0;

      this.fromDate = null;
       this.filterByYear = 0;
      this.toDate = null;
      this.customDate = false;
      this.weekly = false;
      this.monthly = false;
      this.yearly = false;
    }

    let fromDate;
        let toDate;

    if (this.fromDate && !this.toDate) {
      fromDate = this.fromDate;
      fromDate.setHours(0, 0, 0, 0);
      toDate=new Date();
            toDate.setHours(23, 59, 59, 999);


    }

    if (this.toDate && this.fromDate) {
      fromDate = this.fromDate;
      fromDate.setHours(0, 0, 0, 0);
    }
    if (this.toDate) {
      toDate = this.toDate;
      toDate.setHours(23, 59, 59, 999);
    }

    if (fromDate && toDate) {
      if (fromDate.getTime() > toDate.getTime()) {
        this.$refs.startDatePicker["selectedDate"] = null;

        this.fromDate = null; // Bind null value to start date
        fromDate = this.fromDate;

        this.$snotify.warning("From date can not be greater than to date");
        return;
      }
    }
    // else if(fromDate){
    //   // this.fromDate;

    // }
    if (this.filterByMonth == null) {
      fromDate = null;
      toDate = null;
    }

    try {
      if (fromDate == undefined) {
        let orgDate = new Date(this.organisationStartDate);
        fromDate = new Date(orgDate.setHours(0, 0, 0, 0));
      }
      this.skip = mountCall ? 0 : this.skip + 15;
      let response = await Axios.post(
        BASE_API_URL + "administrator/getAllExpense",
        {
          selectedYear: this.filterByYear,
          selectedMonth: this.filterByMonth,
          selectedFromDate: fromDate,
          selectedToDate: toDate,
          options: {
            skip: this.skip,
            limit: 15,
          },
          exportType: exportType,
        },
        {
          headers: authHeader(),
        }
      );


      // for 2nd click download
      // if (exportType == "xls") {
      //   this.xlsData = response.data.expenseList;
      // } else {
      //   //code related to pagination
      //   this.totalLength = response.data.length;
      //   this.currentLengthOnScroll = this.expenseList.length;

      //   if (mountCall) {
      //     this.expenseList = response.data.expenseList
      //       ? response.data.expenseList
      //       : [];
      //   } else {
      //     let data = response.data.expenseList ? response.data.expenseList : [];
      //     this.expenseList = this.expenseList.concat(data);
      //   }
      //   if (response.data.totalAmount[0]) {
      //     this.totalAmount = response.data.totalAmount[0].total;
      //   }
      // }


     // for 1st click download


        this.xlsData = response.data.expenseList;
        //code related to pagination
        this.totalLength = response.data.length;
        this.currentLengthOnScroll = this.expenseList.length;

        if (mountCall) {
          this.expenseList = response.data.expenseList
            ? response.data.expenseList
            : [];
        } else {
          let data = response.data.expenseList ? response.data.expenseList : [];
          this.expenseList = this.expenseList.concat(data);
        }
        if (response.data.totalAmount[0]) {
          this.totalAmount = response.data.totalAmount[0].total;
        }

      this.$store.state.fusionLoader = false
      this.loading = false;
    } catch (error) {
      this.$store.state.fusionLoader = false;
      console.log(error);
    }
  }

  public async getExcelData() {
    this.xlsArr = [];
    let exportDetails: any = [];
    // await this.getExpenseList(true, null, null, "xls");
    let name: any = null;
    if (this.xlsData && this.xlsData.length > 0) {
      this.NewxlsData = this.xlsData.map((e, i) => {
        if (e.expenseEmployeeName) {
          name = e.expenseEmployeeName.personalInformation.name.middleName
            ? e.expenseEmployeeName.personalInformation.name.firstName +
              " " +
              e.expenseEmployeeName.personalInformation.name.middleName +
              " " +
              e.expenseEmployeeName.personalInformation.name.lastName
            : e.expenseEmployeeName.personalInformation.name.firstName +
              " " +
              e.expenseEmployeeName.personalInformation.name.lastName;
        } else {
          name = " ";
        }
        let category = e.expenseCategoryName.categoryName;
        return {
          Category: category,
          "Expense On": e.expenseOn,
          Quantity: e.quantity,
          Amount: e.amount,
          Details: e.spentDetails,
          "Spent Date": moment(e.spentDate).format("DD-MM-YYYY"),
          Purchaser: name,
          Seller: e.seller,
          "Paid Through": e.paidThrough,
        };
      });
      const iterator = this.NewxlsData.values();
      for (const value of iterator) {
        if (value.length > 1) {
          for (let i = 0; i < value.length; i++) {
            this.xlsArr.push(value[i]);
          }
        } else {
          this.xlsArr.push(value);
        }
      }
      if (this.$refs.excel) {
        return this.$refs.excel["xlsData"]({
          data: this.xlsArr,
        });
      }
    }
  }

  public openDeletePopup(id) {
    this.$modal.show("deleteDetails");
    this.deleteExpenseId = id;
  }

  /**
   * to delete any specefic expense detail
   **/
  public async deleteExpenseDetails() {
    try {
      let response = await Axios.post(
        BASE_API_URL + "administrator/deleteExpenseDetails",
        { userId: this.deleteExpenseId },
        {
          headers: authHeader(),
        }
      );
      this.$modal.hide("deleteDetails");
      this.$snotify.remove();
      this.$snotify.success(response.data.message);

      this.getExpenseList(true, null, null, "");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * to edit the details of any specefic list
   **/
  // public async editExpenseDetails(expenseData) {
  //     let data = JSON.parse(JSON.stringify(expenseData));

  //   this.viewAll = false;
  //   this.inputValues = data;
  //    let Data=Array();

  //     this.employeeList.forEach((e: any)=>{
  //       if(e.userId==this.inputValues.purchaser){
  //        Data.push(e);
  //       }

  //     })
  //           this.inputValues.purchaser = Data[0];
  //           this.inputValues.purchaser = this.inputValues.purchaser.userId;

  //           let D=Array();
  //           this.categoryList.forEach((element:any)=>{
  //             if(element._id==this.inputValues.expenseCategoryId){
  //               D.push(element);
  //             }
  //           })
  //           this.inputValues.expenseCategoryId = D[0];


  //   this.$modal.show("detailsModel");
  //   this.addedType = "Edit Expense Details";
  //   this.submitButton = "Update";
  //   this.categoryList = JSON.parse(JSON.stringify(this.categoryList));
  // }

 public async editExpenseDetails(expenseData) {
 this.inProcessings=false;
    let data = JSON.parse(JSON.stringify(expenseData));
    this.viewAll = false;
    this.inputValues = data;

    this.$modal.show("detailsModel");
    this.addedType = "Edit Expense Details";
    this.submitButton = "Update";
    this.categoryList = JSON.parse(JSON.stringify(this.categoryList));
  }
  /**
   * to upload bill documents in add and edit fields
   **/
  public async uploadBillDocuments(event) {
    try {
      if (event.target.files.length > 0) {
        Object.values(event.target.files).forEach((element: any) => {
          this.inputValues.bill.push(element);
        });
        this.billArrayLength = this.billArrayLength + 1;
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * to view the uploaded document
   **/
  public async viewExpenseBill(bill, i) {
    try {
      let files = bill.map((a) => a.path);
      let selectedFile = Array();
      selectedFile.push(files[i]);
      if (this.viewAll) {
        if (files.length > 0)
          this.$refs.displayFiles["displayDocuments"](files);
        else {
          this.$modal.show("showBill");
        }
      } else {
        if (files.length > 0)
          this.$refs.displayFiles["displayDocuments"](selectedFile);
        else {
          this.$modal.show("showBill");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * to view the uploaded document which is not saved in AWS yet
   **/
  public async viewExpenseLocalBill(i, files) {
    try {
      this.$refs.ExpenseView["viewExpenseLocalBill"](files[i]);
      this.$modal.show("showLocalBill");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * to delete any unwanted document
   **/
  public async deleteBillPopup(i,filePath){
    this.$modal.show("deleteBillDocument")
    this.i = i;
    this.filePath = filePath
  }
  public async deleteBillDocument() {
    await Axios.post(
      BASE_API_URL + "administrator/deleteBillDocument",
      {
        data: this.inputValues.bill[this.i],
        expenseId: this.inputValues._id,
        filePath: this.filePath,
      },
      {
        headers: authHeader(),
      }
    );
    this.inputValues.bill.splice(this.i, 1);
    this.$modal.hide("deleteBillDocument")
  }

  /**
   * display week ranges in dropdown
   **/
  public get weeks() {
    let year = this.filterByYear;
    let month = this.filterByMonth;
    let startDate = moment([year, month - 1]);
    let endDate = moment(startDate).endOf("month");

    var weeks = Array();
    var weekRange;
    var weekFromTo = Array();
    var per_week = Array();
    var difference = endDate.diff(startDate, "days");

    per_week.push(startDate.toDate());
    let index = 0;
    let last_week = false;
    while (startDate.add(1, "days").diff(endDate) < 0) {
      if (startDate.day() != 0) {
        per_week.push(startDate.toDate());
      } else {
        if (startDate.clone().add(7, "days").month() == month - 1) {
          weeks.push(per_week);
          per_week = [];
          per_week.push(startDate.toDate());
        } else if (Math.abs(index - difference) > 0) {
          if (!last_week) {
            weeks.push(per_week);
            per_week = [];
          }
          last_week = true;
          per_week.push(startDate.toDate());
        }
      }
      index += 1;

      if (
        (last_week == true && Math.abs(index - difference) == 0) ||
        (Math.abs(index - difference) == 0 && per_week.length == 1)
      ) {
        weeks.push(per_week);
        for (let i = 0; i < weeks.length; i++) {
          let first = weeks[i];
          let firstWeekDay = first[0];
          let lastWeekDay = first[first.length - 1];
          weekRange = firstWeekDay + "|" + lastWeekDay;

          weekFromTo.push(weekRange);
        }
      }
    }
    return weekFromTo;
  }
public async getWeekOfMonth() {
    var d: any = new Date();
  

      d = new Date(d);
    
    var date = d.getDate();
    var day = d.getDay();
    this.weekNumber = Math.ceil((date + 6 - day) / 7);
    // if()#add-timesheet

    this.filterByWeek = this.weeks[this.weekNumber - 1];
  }

  async beforeMount(){
    this.hasAccess = this.$store.state.sessionObject.crudRight ? this.$store.state.sessionObject.crudRight:{}
   
  }

  async mounted() {
    // this.getExcelData();
    this.getEmployeeList();
    this.fetchExpenseCategory();
    this.getWeekOfMonth();
    this.getExpenseList(true, null, null, "");
    await this.getOrganisationInfo();
  }
}
