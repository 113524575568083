





















import { Component, Vue, Prop } from "vue-property-decorator";
import JsonExcel from "vue-json-excel";


@Component({
  components: {
    JsonExcel,
  },
})
export default class SpreadSheet extends Vue {
  public jsonData: any = [];
  public json_fields: any = null;
  static startDownload: any;
  public downloadXlsAccess: boolean = false;

  public async xlsData(body) {
    try {
      
       if(!this.counter){
      this.jsonData = body.data;
       }
      
    } catch (error) {
      console.log(error);
    }
  }
  public counter:boolean=false
  
  startDownload() {
    this.counter= false
    if(this.downloadXlsAccess){
      this.$emit("callMountFunction");
    }
    
  }
  finishDownload() {
    this.jsonData = []
    this.jsonData = {}
    this.jsonData = null
    this.counter = true
  }
  
  async mounted(){
    // await this.xlsData(true)
  
    let hasAccess = this.$store.state.sessionObject.crudRight ? this.$store.state.sessionObject.crudRight:{}; 
    
    if(Object.keys(hasAccess).length && !hasAccess.isEdit){
      this.downloadXlsAccess = false;
    }else{
      this.downloadXlsAccess = true;
    }

  }
}
